import React, { useState, useEffect } from "react";
import wrapperBg from "../assets/img/football/desktop/Mask-Group-5.webp";
// import Group54 from "../assets/img/football/desktop/Group-54.webp";
import Group54 from "../assets/img/football/desktop/Group542x.png";
import logo from "../assets/img/football/desktop/logo.webp";
import MaskGroup4 from "../assets/img/football/desktop/Mask-Group-4.webp";
import qr from "../assets/img/football/desktop/adobe-express-qr-code.svg";

import "../assets/css/Loader.css";
import "../assets/css/DesktopPage.css";

import TagManager from "react-gtm-module";

export const DesktopPage = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (loading) {
      const ele = document.getElementById("ac-progess-loading");
      if (ele) {
        ele.remove();
        setLoading(false);
      }
    }
  }, [loading]);
 
  TagManager.dataLayer({ dataLayer: { event: "sms_sent_desktop" } });
  return (
    <div className="ac-desktop-page ac-loader-wrapper">
      <img src={MaskGroup4} alt="" className="ac-img-responsive ac-bg"/>
      <img src={logo} alt="הרכב המנצח"  className="ac-img-responsive ac-logo"/>
      <div className="ac-content">
        <img src={wrapperBg} alt="" className="ac-img-responsive ac-wrapperBg"/>
        <span className="ac-title">ברוכים הבאים למשחק!</span>
        <span className="ac-main-text">
        מצאו את שחקני הנבחרת של מילקה ואוראו ותוכלו לזכות <br/>
          בחבילה חלומית בשווי 20,000 ₪ לאירוע ספורט או <br/>
          מוזיקה לבחירתכם
        </span>
        <span className="ac-star">*למימוש בקבוצת איסתא</span>
        <div className="ac-col-wrapper">
          <div className="ac-col-1">
             <img src={Group54} alt="" className="ac-img-responsive ac-Group54"/>
             <span className="ac-img-text">על מנת לזכות, יש להציג חשבונית על <br/>קניית מוצרי אוראו ומילקה בסך 30 ₪</span>
          </div>
          <div className="ac-col-2"><span></span></div>
          <div className="ac-col-3">
             <img src={qr} alt="" className="ac-img-responsive ac-qr"/>
             <span className="ac-img-text">סרקו וכנסו למשחק</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopPage;
