import React from "react";

export const ServerTime = () => {
  var offset = 0;

  function calcOffset() {
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.open("GET", "https://stackoverflow.com/", false);
    xmlhttp.send();

    var dateStr = xmlhttp.getResponseHeader("Date");
    var serverTimeMillisGMT = Date.parse(
      new Date(Date.parse(dateStr)).toUTCString()
    );
    var localMillisUTC = Date.parse(new Date().toUTCString());

    offset = serverTimeMillisGMT - localMillisUTC;
  }

  function getServerTime() {
    var date = new Date();

    date.setTime(date.getTime() + offset);

    return date;
  }
  function getFormattedString(d) {
    return (
      d.getFullYear() +
      "-" +
      (d.getMonth() + 1) +
      "-" +
      d.getDate() +
      " " +
      d.toString().split(" ")[4]
    );
    // for time part you may wish to refer http://stackoverflow.com/questions/6312993/javascript-seconds-to-time-string-with-format-hhmmss
  }
  const $startServerTime = getFormattedString(getServerTime());

  // console.log($startServerTime);
  return $startServerTime;
};

export const TouchMove = () => {
  window.addEventListener("touchmove", (event) => {
    function handleOrientation(event) {
      // console.log("Event: ", event);
      // // Do stuff with the new orientation data
      // console.log(event.currentTarget.AFRAME.scenes[0].clock.elapsedTime); //TIME
      // console.log(
      //   event?.srcElement?.AFRAME?.scenes[0]?.behaviors?.tick[0]?.yawObject
      //     ?.rotation?._y
      // );
    }
    return window.addEventListener(
      "deviceorientation",
      handleOrientation,
      true
    );
  });
};
