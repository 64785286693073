import React from "react";
import findOreoImg from "../../assets/img/football/found.png";


const Step_4 = (type) => {
  switch (type) {
    case "D1":
      return D1;
    case "D2":
      return D2;
    default:
      break;
  }
};
export default Step_4;
const D1 = (found, notFound, requiredCoordinates) => {
  const handleFound = (position, id) => {
    found(position, id);
  };
  const handleFound2 = (position, id) => {
    found(position, id);
  };
  const handleFound3 = (position, id) => {
    found(position, id);
  };
  return (
    <a-scene
       // rotation="0 0 0"
      rotation="45 90 180"
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-assets>
        <img id="texture1" src={findOreoImg} alt="Texture 1"/>
      </a-assets>
      {requiredCoordinates >= 1 && (
      <a-image
        // Circle 1
        onClick={() => handleFound("1 -0.5 -4", "1")}
        position="1 -0.5 -4"
        width="1.5"
        height="1.5"
        // color="white"
        src="#texture1"
        rotation="0 -4 -17"
        material="opacity: 0; transparent: true"
          // material="opacity: 1; transparent: true" SHOULD BE
          // material="opacity: 1; transparent: false"
      />
      )}
      {requiredCoordinates >= 2 && (
      <a-image
        // Circle 2
        onClick={() => handleFound2("4 2.6 4.8", "2")}
        position="4 2.6 4.8"
        width="2"
        height="2"
        // color="grey"
        src="#texture1"
        rotation="0 58 0"
        material="opacity: 0; transparent: true"
          // material="opacity: 1; transparent: true" SHOULD BE
          // material="opacity: 1; transparent: false"
      />)}
      {requiredCoordinates >= 3 && (
      <a-image
        //C6
        onClick={() => handleFound3("-6 7.8 4.5", "3")}
        position="-6 7.8 4.5"
        width="2"
        height="2"
        // color="black"
        src="#texture1"
        rotation="-78 -119 59"
        material="opacity: 0; transparent: true"
          // material="opacity: 1; transparent: true" SHOULD BE
          // material="opacity: 1; transparent: false"
      />
      )}
     
      <a-sky src={`data:image/jpeg;base64,${window.D1.image.data}`} />
    </a-scene>
  );
};
const D2 = (found, notFound, requiredCoordinates) => {
  const handleFound = (position, id) => {
    found(position, id);
  };
  const handleFound2 = (position, id) => {
    found(position, id);
  };
  const handleFound3 = (position, id) => {
    found(position, id);
  };
  return (
    <a-scene
       // rotation="0 0 0"
      rotation="45 90 180"
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-assets>
        <img id="texture1" src={findOreoImg} alt="Texture 1"/>
      </a-assets>
      {requiredCoordinates >= 1 && (
      <a-image
        // Circle 1
        id="1"
        onClick={() => handleFound("-0.5 1.7 -3", "1")}
        position="-0.5 1.7 -3"
        width="1"
        height="1"
        // color="yellow"
        src="#texture1"
        rotation="8 0 -12"
        material="opacity: 0; transparent: true"
          // material="opacity: 1; transparent: true" SHOULD BE
          // material="opacity: 1; transparent: false"
      />)}
      {requiredCoordinates >= 2 && (
      <a-image
        // Circle 2
        id="2"
        onClick={() => handleFound2("-4 -1.5 -1.9", "2")}
        position="-4 -1.5 -1.9"
        width="1.5"
        height="1.3"
        // color="orange"
        src="#texture1"
        rotation="-31 34 -1"
        material="opacity: 0; transparent: true"
          // material="opacity: 1; transparent: true" SHOULD BE
          // material="opacity: 1; transparent: false"
      />
      )}
      {requiredCoordinates >= 3 && (
      <a-image
        // Circle 3
        id="3"
        onClick={() => handleFound3("-1.5 10 9.5", "3")}
        position="-1.5 10 9.5"
        width="2.5"
        height="2.5"
        // color="red"
        src="#texture1"
        rotation="8 -8 -12"
        material="opacity: 0; transparent: true"
          // material="opacity: 1; transparent: true" SHOULD BE
          // material="opacity: 1; transparent: false"
      />
      )}
      
      <a-sky src={`data:image/jpeg;base64,${window.D2.image.data}`} />
    </a-scene>
  );
};

