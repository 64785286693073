import React, { useState, useEffect } from "react";
import PopupEnd from "../Components/PopupEnd";
import img6546 from "../assets/img/football/6546.webp";
import { Link, useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";
import CryptoJS from "crypto-js";

function convertTimeFormat(time) {
  if (!time) return '';

  // Split the time string into an array using ':' as the separator
  const timeParts = time.split(':');

  // Extract minutes and seconds
  const minutes = timeParts[0];
  const seconds = timeParts[1];

  // Return the formatted time as MM:SS
  return `${minutes}:${seconds}`;
}

const ThanksPopup = (props) => {
  const [closePopup, setClosePopup] = useState(false);
  const [finalTime, setFinalTime] = useState('');
  const location = useLocation();
  const { gameTimer, timer, utmMedium, utmSource, utm_val, isDev, serverStart } = location.state || {};

  const secretKey = process.env.REACT_APP_SECRET_KEY;

  if (!secretKey) {
    console.error("Secret key is not defined");
  } else {
    console.log("Secret key loaded:", secretKey);
  }

  const decryptData = (ciphertext, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  const loadFromLocalStorage = (key, secretKey) => {
    const encryptedData = localStorage.getItem(key);
    if (encryptedData) {
      return decryptData(encryptedData, secretKey);
    }
    return null;
  };

  const decryptedUserData = loadFromLocalStorage("user", secretKey);
  
  useEffect(() => {
    const userCodeData = localStorage.getItem('userCode');
    if (userCodeData) {
      const { finalTime } = JSON.parse(userCodeData);
      setFinalTime(finalTime);
    }
  }, [finalTime]);
  
  const eventClick = () => {
    TagManager.dataLayer({ dataLayer: { event: "yalla" } });
  };

  return (
    <div
      id="ac-gaveover-page"
      events={eventClick}
      className={closePopup ? "ac-close" : null}
    >
      <img src={img6546} alt="" className="ac-img-responive ac-img6546" />
     <div className="ac-overlay"></div>
    
      
    
     <PopupEnd id="ac-found-oreo" events={props.clickEvent}>
      <div className="ac-text-box">
        <span className="ac-popuptitle">עשית זאת!</span>
        <br/>
        <span className="ac-text ac-text-b ac-text-b-1">מצאת את כל 11 שחקני הנבחרת<br/> המנצחת של מילקה אוראו בזמן-</span>
      </div>
      <span className="ac-your-time">{decryptedUserData}</span>

      <Link to="/FormPage" onClick={() => {
        if (props.clickEvent) {
          props.clickEvent(); 
      }
      TagManager.dataLayer({ dataLayer: { event: "finished_sign_up" } });
        }}
         className="ac-btn ac-btn-goto-form">
        <div className="ac-inner-btn">
          <span>הרשמה לתחרות</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="15.75" height="18.195" viewBox="0 0 15.75 18.195">
                <g id="Group_11" data-name="Group 11" transform="translate(-136 -608.792)">
                  <g id="ctrl-left" transform="translate(134.504 608.395)">
                    <g id="Group_10" data-name="Group 10" transform="translate(8.496 0.397)">
                      <path id="Path_7" data-name="Path 7" d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z" transform="translate(-8.496 -0.397)" fill="#fff"/>
                    </g>
                  </g>
                  <g id="ctrl-left-2" data-name="ctrl-left" transform="translate(127.504 608.395)">
                    <g id="Group_10-2" data-name="Group 10" transform="translate(8.496 0.397)">
                      <path id="Path_7-2" data-name="Path 7" d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z" transform="translate(-8.496 -0.397)" fill="#fff"/>
                    </g>
                  </g>
                </g>
          </svg>
        </div>
      </Link>
    </PopupEnd>
    </div>
  );
};

export default ThanksPopup;
