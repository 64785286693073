import React, { useState,useEffect } from 'react';
import TagManager from "react-gtm-module";
import text_bg from "../assets/img/football/1.webp";
import axios from 'axios';

const UploadForm = (props) => {
  const [text_2, setText_2] = useState('');
  const [image, setImage] = useState(null);
  const [hidden_8, setHidden_8] = useState(null);
  const [message, setMessage] = useState('');
  const [leadSent, setLeadSent] = useState(false);
 


  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    // console.log(image);
  };

  useEffect(() => {
    if (image) {
      // console.log(image);
      setHidden_8(`https://milkaoreo.activated.digital/invoices_from_app/user_images/${image.name}`);
    }
  }, [image]);

  const handleSubmit = async (event) => {
    event.preventDefault();
   

      // Save invoice in the server in "invoices_from_app/user_images" folder
      const formDataImg = new FormData();
      formDataImg.append('file', image);

      try {
        const response = await axios.post('https://milkaoreo.activated.digital/invoices_from_app/', formDataImg, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        // console.log(response.data);
       
  
        if (response.data.status === 'ok') {
          setMessage(`File uploaded successfully: ${response.data.filename}`);
        } else {
          setMessage('File upload failed');
        }
      } catch (error) {
        setMessage('An error occurred while uploading the file');
      }

   

      try {
        let res = await fetch(
          "https://crud.activated.digital/app/app/f?id=54",
          {
            method: "POST",
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({ text_2, hidden_8 }),
          }
        );
        let resJson = await res.json();
        TagManager.dataLayer({ dataLayer: { event: "receipt_menu_upload" } });
        if (res.status === 200) {
          // console.log(res);
          setLeadSent(true);
          
     
          setText_2("");
          setMessage("קבלנו את הקבלה");
          
        } else {
          setMessage("Some error occurred");
        }
      } catch (err) {
        // console.log(err);
      }
   
  };

  

  return (
   
      <>
        {leadSent ? (
          <div class="ac_help_center">
                <div className='ac-thanks'>
                    <div className="ac-text-box">
                        <span className="ac-text ac-text-r ac-text-r-1">תודה השתתפותך בפעילות<br/> 
                          ׳מילקה אוראו ההרכב המנצח׳ <br/>
                          פרטיך נשמרו בהצלחה. אנו ניצור איתך <br/>
                          קשר במקרה של זכייה. <br/><br/>
                          אגב, ניתן לשחק שוב ושוב על מנת להגדיל <br/>
                          את סיכוייך לזכות. בהצלחה!
                        </span>
                        <div onClick={() => {
                          const currentUrl = window.location.origin;
                          window.location.assign(currentUrl);
                        }} className="ac-btn ac-start-game-btn"  role="button">
                          <div className="ac-inner-btn">
                            <span>להתחלת המשחק</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15.75" height="18.195" viewBox="0 0 15.75 18.195">
                              <g id="Group_11" data-name="Group 11" transform="translate(-136 -608.792)">
                                <g id="ctrl-left" transform="translate(134.504 608.395)">
                                  <g id="Group_10" data-name="Group 10" transform="translate(8.496 0.397)">
                                    <path id="Path_7" data-name="Path 7" d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z" transform="translate(-8.496 -0.397)" fill="#fff"/>
                                  </g>
                                </g>
                                <g id="ctrl-left-2" data-name="ctrl-left" transform="translate(127.504 608.395)">
                                  <g id="Group_10-2" data-name="Group 10" transform="translate(8.496 0.397)">
                                    <path id="Path_7-2" data-name="Path 7" d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z" transform="translate(-8.496 -0.397)" fill="#fff"/>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                    </div>
                </div>
          </div>
          
        ) : (
        <form onSubmit={handleSubmit}>
          <img src={text_bg} alt="" className="ac-img-responive ac-text-bg-box" />
          <div className="ac-text-box">
            <span className="ac-text ac-text-m ac-text-m-1">העלאת חשבונית</span>
          </div>
         
          <div className='ac-input'>
            <label className="ac-hide">נייד:</label>
            <input 
              type="text" 
              value={text_2} 
              placeholder="נייד"
              onChange={(e) => setText_2(e.target.value)} 
              maxLength={10}
              required
            />
          </div>
          <div className='ac-input ac-input-upload'>
          <input 
              id="upload-button" 
              type="file" 
              accept="image/*" 
              style={{ display: 'none' }} 
              onChange={handleImageChange} 
              required
            />
            <label htmlFor="upload-button">
              <span className='ac-icon-upload-image'>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={26.55}
                  height={24.195}
                
                >
                  <g
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="square"
                    strokeWidth={3}
                    data-name="Group 32"
                    transform="translate(1.5 1.5)"
                  >
                    <circle
                      cx={4.317}
                      cy={4.317}
                      r={4.317}
                      data-name="Ellipse 3"
                      transform="translate(7.457 7.85)"
                    />
                    <path
                      d="M21.195 21.195H2.355A2.355 2.355 0 0 1 0 18.84V6.28a2.355 2.355 0 0 1 2.355-2.355h3.532L8.242 0h7.065l2.355 3.925h3.532A2.355 2.355 0 0 1 23.55 6.28v12.56a2.355 2.355 0 0 1-2.355 2.355Z"
                      data-name="Path 18"
                    />
                  </g>
                </svg>
                </span> צילום חשבונית
            </label>
          </div>
        
          <button type="submit" className='send'>
            <span>שליחה</span>
            <span className='ac-arrows'>
              <svg xmlns="http://www.w3.org/2000/svg" width="15.75" height="18.195" viewBox="0 0 15.75 18.195">
                      <g id="Group_11" data-name="Group 11" transform="translate(-136 -608.792)">
                        <g id="ctrl-left" transform="translate(134.504 608.395)">
                          <g id="Group_10" data-name="Group 10" transform="translate(8.496 0.397)">
                            <path id="Path_7" data-name="Path 7" d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z" transform="translate(-8.496 -0.397)" fill="#fff"/>
                          </g>
                        </g>
                        <g id="ctrl-left-2" data-name="ctrl-left" transform="translate(127.504 608.395)">
                          <g id="Group_10-2" data-name="Group 10" transform="translate(8.496 0.397)">
                            <path id="Path_7-2" data-name="Path 7" d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z" transform="translate(-8.496 -0.397)" fill="#fff"/>
                          </g>
                        </g>
                      </g>
              </svg>
            </span>
          </button>
          {/* <div className='ac-checkbox'>
            <label>
              <input 
                type="checkbox" 
                checked={checked} 
                onChange={(e) => setChecked(e.target.checked)} 
              />
          <span>ידוע לי שכדי לזכות, צריך לצלם חשבונית על שני מוצרי מילקה (שוקולד ו/או עוגיות)</span>
            </label>
          </div> */}
          {/* <div className='ac-checkbox'>
            <label>
              <input 
                type="checkbox" 
                checked={checkedTakanon} 
                onChange={(e) => setCheckedTakanon(e.target.checked)} 
              />
                <span>קראתי ואני מסכים לתקנון</span>
            </label>
          </div> */}
          
          {message && <p>{message}</p>}
        </form>
      )}
     </>
    
  );
};

export default UploadForm;
