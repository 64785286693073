import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/img/football/logo.webp";
import "../assets/css/GoalsPage.css";
import GamePage from "./GamePage.jsx";
import TagManager from "react-gtm-module";

export const GoalsPage = (props) => {
  return (
    <div className="ac-goals-page"
    style={{
      backgroundImage: `url(${
        process.env.PUBLIC_URL + "/img/Mask-Group-6.webp"
      })`,
      backgroundPosition: "center",
      backgroundRepeat: "noRepeat",
      backgroundSize: "100%",
    }}
    >
      <div className="ac-overlay"></div>
      <div className="ac-container">
        {/* <div className="bread">
          <Link
            to="/"
            onClick={() => {
              window.location.replace("https://football-milkaoreo.activated.digital/");
              TagManager.dataLayer({ dataLayer: { event: "homepage" } });
            }}
          >
            בית{" "}
          </Link>{" "}
          {" > "}
          <Link
            to="/SharePage"
            onClick={() => {
              TagManager.dataLayer({ dataLayer: { event: "true_friend" } });
            }}
          >
            חבר אמיתי משתף
          </Link>
        </div> */}
        <div className="ac-img-wrapper">
          <img src={logo} alt="Logo" className="ac-img-responive ac-logo" />
        </div>
        <div className="ac-close-wrapper">
          <Link to="/" onClick={() => {
              // window.location.replace("https://football-milkaoreo.activated.digital/");
              TagManager.dataLayer({ dataLayer: { event: "lets_start" } });
            }} className="ac-close">
            <div className="ac-inner-btn">
              <span>+</span>
            </div>
          </Link>
        </div>
        <span className="ac-title">
           פרסים:
        </span>
        <span className="ac-text">הפרס הגדול:<br/>
          חבילה חלומית בשווי 20,000 ₪ <br/>
          לאירוע ספורט או מוזיקה בחו"ל 
        </span>

        <span className="ac-text">פרס יומי: <br/>
          מדי יום יחולקו חבילת מוצרי <br/>
          אוראה ומילקה ל-3 זוכים בעלי <br/>
          התוצאה הטובה ביותר באותו היום
        </span>

      
        
      
      </div>
    </div>
  );
};

export default GoalsPage;
