import React from 'react';

const ProgressBar = ({ progress, current, total }) => {
  const progressStyle = {
    width: `${progress}%`,
    height: '100%',
    backgroundColor: '#0044cc',
    borderRadius: '10px 0 0 10px',
    transition: 'width 0.5s',
  };

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ccc',
    borderRadius: '10px',
    height: '20px',
    width: '100%',
    overflow: 'hidden',
  };

  const textStyle = {
    marginLeft: '10px',
    color: '#fff',
   
  };

  return (
    <div className="ac-prograssbar">
        <div class="" style={containerStyle}>
            <div style={progressStyle}></div>
        </div>
        <span style={textStyle}>
            {current}/{total}
        </span>
    </div>
    
  );
};

export default ProgressBar;
