import React from "react";
import findOreoImg from "../../assets/img/football/found.png";


const Step_2 = (type) => {
  switch (type) {
    case "B1":
      return B1;
    case "B2":
      return B2;
    default:
      break;
  }
};



const B1 = (found, notFound, requiredCoordinates) => {
  const handleFound = (position, id) => {
    found(position, id);
  };
  const handleFound2 = (position, id) => {
    found(position, id);
  };
  const handleFound3 = (position, id) => {
    found(position, id);
  };
  return (
    <a-scene
      // rotation="0 0 0"
      rotation="45 90 180"
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-assets>
        <img id="texture1" src={findOreoImg} alt="Texture 1"/>
      </a-assets>
      {requiredCoordinates >= 1 && (
      <a-image
        // Circle 1
        id="1"
        onClick={() => handleFound("2 2 -0.9", "1")}
        position="2 2 -0.9"
        width="1"
        height="1"
        // color="white"
        src="#texture1"
        rotation="0 -60 0"
        material="opacity: 0; transparent: true"
        // material="opacity: 1; transparent: true" SHOULD BE
        // material="opacity: 1; transparent: false"
      />
      )}
      {requiredCoordinates >= 2 && (
      <a-image
        // Circle 2
        id="2"
        onClick={() => handleFound2("-6 2 -0.5", "2")}
        position="-6 2 -0.5"
        width="2"
        height="2"
        // color="grey"
        src="#texture1"
        rotation="8 70 -12"
        material="opacity: 0; transparent: true"
          // material="opacity: 1; transparent: true" SHOULD BE
          // material="opacity: 1; transparent: false"
      />
      )}
      {requiredCoordinates >= 3 && (
      <a-image
        // Circle 3
        id="3"
        onClick={() => handleFound3("-0.5 -4 7.5", "3")}
        position="-0.5 -4 7.5"
        width="2"
        height="2"
        // color="black"
        src="#texture1"
        rotation="8 0 0"
        material="opacity: 0; transparent: true"
        // material="opacity: 1; transparent: true" SHOULD BE
        // material="opacity: 1; transparent: false"
      />
      )}
      
      <a-sky src={`data:image/jpeg;base64,${window.B1.image.data}`} />
    </a-scene>
  );
};
const B2 = (found, notFound, requiredCoordinates) => {
  const handleFound = (position, id) => {
    found(position, id);
  };
  const handleFound2 = (position, id) => {
    found(position, id);
  };
  const handleFound3 = (position, id) => {
    found(position, id);
  };
  return (
    <a-scene
       // rotation="0 0 0"
      rotation="45 90 180"
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-assets>
        <img id="texture1" src={findOreoImg} alt="Texture 1"/>
      </a-assets>
      {requiredCoordinates >= 1 && (
      <a-image
        // Circle 1
        id="1"
        onClick={() => handleFound("0.2 2.1 -2.8", "1")}
        position="0.2 2.1 -2.8"
        width="1"
        height="1"
        // color="yellow"
        src="#texture1"
        rotation="8 0 -12"
        // material="opacity: 0; transparent: true" SHOULD BE
        material="opacity: 1; transparent: true" 
        // material="opacity: 1; transparent: false"
      />
      )}
       {requiredCoordinates >= 2 && (
      <a-image
        // Circle 2
        id="2"
        onClick={() => handleFound2("-4 -2.1 -2.3", "2")}
        position="-4 -2.1 -2.3"
        width="1.5"
        height="1.5"
        // color="orange"
        src="#texture1"
        rotation="-30 21 4"
        // material="opacity: 0; transparent: true" SHOULD BE
        material="opacity: 1; transparent: true" 
        // material="opacity: 1; transparent: false"
      />
      )}
      {requiredCoordinates >= 3 && (
      <a-image
        // Circle 3
        id="3"
        onClick={() => handleFound3("-5 1.7 2", "3")}
        position="-5 1.7 2"
        width="2"
        height="2"
        // color="red"
        src="#texture1"
        rotation="4 92 -12"
        // material="opacity: 0; transparent: true" SHOULD BE
        material="opacity: 1; transparent: true" 
        // material="opacity: 1; transparent: false"
      />
      )}
      
      <a-sky src={`data:image/jpeg;base64,${window.B2.image.data}`} />
    </a-scene>
  );
};

export default Step_2;
