import React, { useState } from "react";
import findOreoImg from "../assets/img/1.svg";
import BannerGame from "../Components/BannerGame";
import { formatTime, formatTime_back } from "../Components/Chrono";
import { useSteps } from "../Components/Steps";
import VRScene from "../Components/VRScene.jsx";
import Countdown from "../Components/Countdown";

export const GamePage = (props) => {
  const [isNotFound, setIsNotFound] = useState(true);
  const [isNotMissed, setIsNotMissed] = useState(true);
  const { ref, handleaddStep } = useSteps();
  const [showTimer, setShowTimer] = useState(false);
  const [showCountdown, setShowCountdown] = useState(true);
  const [countdownFinished, setCountdownFinished] = useState(false);

  const handleCountdownEnd = () => {
    setCountdownFinished(true);
    setShowCountdown(false);
    setShowTimer(true); // Start the timer when countdown ends
  };
  
  return (
    <div className="ac-start-game">
      {showCountdown && !countdownFinished ? (
        <Countdown runCountdown={true} onCountdownEnd={handleCountdownEnd} />
      ) : (
        <>
          <VRScene
            setGameTimer={props.setGameTimer}
            gameTimer={props.gameTimer}
            steps={props.steps}
            step={props.step}
            notOreo={isNotMissed}
            setNotOreo={setIsNotMissed}
            Yalah={props.isNotYalla}
            img={findOreoImg}
            setYalah={props.setIsNotYalla}
            timeer={formatTime_back(props.timer)}
            run={props.handleStart}
            reset={props.handleReset}
            statusGame={ref.current}
            changeStatus={handleaddStep}
            resume={props.handleResume}
            pause={props.handlePause}
            isNotFound={isNotFound}
            setIsNotFound={setIsNotFound}
            VR360={props.VR360}
            isDev={props.isDev}
            onCountdownEnd={handleCountdownEnd} // Pass the handler to start the timer
          />
          {showTimer && (
            <BannerGame step={ref.current} time={formatTime(props.timer)} />
          )}
        </>
      )}
    </div>
  );
};

export default GamePage;
