import React from "react";
import findOreoImg from "../../assets/img/football/found.png";

const Step_1 = (type) => {
  switch (type) {
    case "A1":
      return A1;
    case "A2":
      return A2;
    default:
      break;
  }
};

const A1 = (found, notFound, requiredCoordinates) => {
  const handleFound = (position, id) => {
    found(position, id);
  };
  const handleFound2 = (position, id) => {
    found(position, id);
  };
 

  return (
    <a-scene
      // rotation="0 0 0"
      rotation="45 90 180"
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-assets>
        <img id="texture1" src={findOreoImg} alt="Texture 1"/>
        <img id="texture2" src={findOreoImg} alt="Texture 2"/>
      </a-assets>
      {requiredCoordinates >= 1 && (
        <a-image
          // Circle 1 (Middle Row, Middle-Right Side)
          
          onClick={() => handleFound("-6 2.5 -0", "1")}
          position="-6 2.5 -0"
          width="2"
          height="2"
          // color="black"
          src="#texture1"
          rotation="8 70 -12"
      
          // style={{ pointerEvents: isClicked("-6 2.5 -0", "0.3") ? "none" : "auto" }}
          material="opacity: 0; transparent: true"
          // material="opacity: 1; transparent: true" SHOULD BE
          // material="opacity: 1; transparent: false"
        />
      )}
      {requiredCoordinates >= 2 && (
        <a-image
          // Circle 2 (Bottom Row, Middle-Left Side)
          id="2"
          onClick={() => handleFound2("7 -3 2", "2")}
          position="7 -3 2"
           width="4"
          height="2"
          // color="black"
          src="#texture2"
          rotation="78 -7 0"
       
          // style={{ pointerEvents: isClicked2("7 -3 2", "0.7") ? "none" : "auto" }}
          material="opacity: 0; transparent: true"
          // material="opacity: 1; transparent: true" SHOULD BE
          // material="opacity: 1; transparent: false"
        />
      )}

      <a-sky src={`data:image/jpeg;base64,${window.A1.image.data}`} />
    </a-scene>
  );
};

const A2 = (found,  notFound, requiredCoordinates) => {
  const handleFound = (position, id) => {
    found(position, id);
  };
  const handleFound2 = (position, id) => {
    found(position, id);
  };
 

  return (
    <a-scene
       // rotation="0 0 0"
      rotation="45 90 180"
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-assets>
        <img id="texture1" src={findOreoImg} alt="Texture 1"/>
        <img id="texture2" src={findOreoImg} alt="Texture 2"/>
      </a-assets>
      {requiredCoordinates >= 1 && (
        <a-image
          id="1"
          // Circle 1
          onClick={() => handleFound("1.5 7 -7", "1")}
          position="1.5 7 -7"
          width="2"
          height="2"
          // color="black"
          src="#texture1"
          rotation="36 -7 0"
        
          // style={{ pointerEvents: isClicked("1.5 7 -7", "0.5") ? "none" : "auto" }}
          material="opacity: 0; transparent: true"
          // material="opacity: 1; transparent: true" SHOULD BE
          // material="opacity: 1; transparent: false"
        />
      )}
      {requiredCoordinates >= 2 && (
        <a-image
          id="2"
          // Circle 2
          onClick={() => handleFound2("-6 -2.8 -1.2", "2")}
          position="-6 -2.8 -1.2"
          width="2"
          height="2"
          // color="black"
          src="#texture2"
          rotation="-18 33 28"
    
          // style={{ pointerEvents: isClicked2("-6 -2.8 -1.2", "0.3") ? "none" : "auto" }}
          material="opacity: 0; transparent: true"
          // material="opacity: 1; transparent: true" SHOULD BE
          // material="opacity: 1; transparent: false"
        />
      )}

      <a-sky src={`data:image/jpeg;base64,${window.A2.image.data}`} />
    </a-scene>
  );
};

export default Step_1;
