import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PopupEnd from "../PopupEnd";
import { NotFoundData } from "./NotFoundData";

const NotFoundOreo = (props) => {
  const clickEvent = () => {
    props.desactivate(true);
    props.replay();
    props.desactivate(true);
    props.desactivate(true);
  };

  const [timeLeft, setTimeLeft] = useState(2);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  const Desactivate = () => {
    return (
      <button className="ac-btn ac-btn-grey " disabled={true}>
        <div className="ac-inner-btn">
          <span>({timeLeft}) חזרה למשחק</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.167"
            height="21.168"
            viewBox="0 0 21.167 21.168"
          >
            <g data-name="Group 32" transform="translate(0 10.584)">
              <path
                fill="#0057c8"
                d="M0 0l21.167 10.584v-21.168z"
                data-name="Path 17"
              ></path>
            </g>
          </svg>
        </div> 
      </button>
    );
  };

  return (
    <PopupEnd id="ac-missed-oreo">
      {/* <div className="ac-popup-title">
            <span className="ac-title-name ac-title-name-1">{NotFoundData[props.number].title}</span>
            <span className="ac-title-name ac-title-name-2">{NotFoundData[props.number].title}</span>
        </div> */}
      <p
        dangerouslySetInnerHTML={{ __html: NotFoundData[props.number].desc }}
      />
      {timeLeft === null ? (
        <Link to="" onClick={clickEvent} className="ac-btn ac-btn-start-again">
          <div className="ac-inner-btn">
            <span>חזרה למשחק</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="15.75" height="18.195" viewBox="0 0 15.75 18.195">
                  <g id="Group_11" data-name="Group 11" transform="translate(-136 -608.792)">
                    <g id="ctrl-left" transform="translate(134.504 608.395)">
                      <g id="Group_10" data-name="Group 10" transform="translate(8.496 0.397)">
                        <path id="Path_7" data-name="Path 7" d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z" transform="translate(-8.496 -0.397)" fill="#fff"/>
                      </g>
                    </g>
                    <g id="ctrl-left-2" data-name="ctrl-left" transform="translate(127.504 608.395)">
                      <g id="Group_10-2" data-name="Group 10" transform="translate(8.496 0.397)">
                        <path id="Path_7-2" data-name="Path 7" d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z" transform="translate(-8.496 -0.397)" fill="#fff"/>
                      </g>
                    </g>
                  </g>
                </svg>
          </div>
        </Link>
      ) : (
        <Desactivate />
      )}
    </PopupEnd>
  );
};
export default NotFoundOreo;
