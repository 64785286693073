import React from "react";
import findOreoImg from "../../assets/img/football/found.png";

const Step_3 = (type) => {
  switch (type) {
    case "C1":
      return C1;
    case "C2":
      return C2;
    default:
      break;
  }
};

const C1 = (found, notFound, requiredCoordinates) => {
  const handleFound = (position, id) => {
    found(position, id);
  };
  const handleFound2 = (position, id) => {
    found(position, id);
  };
  const handleFound3 = (position, id) => {
    found(position, id);
  };
  return (
    <a-scene
       // rotation="0 0 0"
      rotation="45 90 180"
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-assets>
        <img id="texture1" src={findOreoImg} alt="Texture 1"/>
      </a-assets>
      {requiredCoordinates >= 1 && (
      <a-image
        // Circle 1
        id="1"
        onClick={() => handleFound("4.7 -1.1 -3", "1")}
        position="4.7 -1.1 -3"
        width="1.5"
        height="1.5"
        // color="white"
        src="#texture1"
        rotation="0 -60 0"
        material="opacity: 0; transparent: true"
        // material="opacity: 1; transparent: true" SHOULD BE
        // material="opacity: 1; transparent: false"
      />
      )}
      {requiredCoordinates >= 2 && (
      <a-image
        // Circle 2
        id="2"
        onClick={() => handleFound2("5 2.1 3.3", "2")}
        position="5 2.1 3.3"
        width="2"
        height="2"
        // color="grey"
        src="#texture1"
        rotation="0 -113 0"
        material="opacity: 0; transparent: true"
        // material="opacity: 1; transparent: true" SHOULD BE
        // material="opacity: 1; transparent: false"
      />)}
      {requiredCoordinates >= 3 && (
      <a-image
        // Circle 2
        id="3"
        onClick={() => handleFound3("-4 -0.8 4.5", "3")}
        position="-4 -0.8 4.5"
        width="1.5"
        height="1.5"
        // color="black"
        src="#texture1"
        rotation="0 -60 0"
        material="opacity: 0; transparent: true"
        // material="opacity: 1; transparent: true" SHOULD BE
        // material="opacity: 1; transparent: false"
      />)}
     
      <a-sky src={`data:image/jpeg;base64,${window.C1.image.data}`} />
    </a-scene>
  );
};
const C2 = (found, notFound, requiredCoordinates) => {
  const handleFound = (position, id) => {
    found(position, id);
  };
  const handleFound2 = (position, id) => {
    found(position, id);
  };
  const handleFound3 = (position, id) => {
    found(position, id);
  };
  return (
    <a-scene
       // rotation="0 0 0"
      rotation="45 90 180"
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-assets>
        <img id="texture1" src={findOreoImg} alt="Texture 1"/>
      </a-assets>
      {requiredCoordinates >= 1 && (
      <a-image
        // Circle 1
        id="1"
        onClick={() => handleFound("-2.3 4 -3", "1")}
        position="-2.3 4 -3"
        width="1"
        height="1"
        // color="yellow"
        src="#texture1"
        rotation="14 28 -17"
        material="opacity: 0; transparent: true"
          // material="opacity: 1; transparent: true" SHOULD BE
          // material="opacity: 1; transparent: false"
      />)}
      {requiredCoordinates >= 2 && (
      <a-image
        // Circle 2
        id="2"
        onClick={() => handleFound2("-0.6 -0.6 5", "2")}
        position="-0.6 -0.6 5"
        width="1"
        height="1"
        // color="orange"
        src="#texture1"
        rotation="8 0 -12"
        material="opacity: 0; transparent: true"
          // material="opacity: 1; transparent: true" SHOULD BE
          // material="opacity: 1; transparent: false"
      />
      )}
     {requiredCoordinates >= 3 && (
      <a-image
        // Circle 2
        id="3"
        onClick={() => handleFound3("1.7 4.9 3.5", "3")}
        position="1.7 4.9 3.5"
        width="1"
        height="1"
        // color="red"
        src="#texture1"
        rotation="-22 12 8"
        material="opacity: 0; transparent: true"
          // material="opacity: 1; transparent: true" SHOULD BE
          // material="opacity: 1; transparent: false"
      />)}
      
      <a-sky src={`data:image/jpeg;base64,${window.C2.image.data}`} />
    </a-scene>
  );
};

export default Step_3;
