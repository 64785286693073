import React, { useState } from "react";
import { Link } from "react-router-dom";
import TagManager from "react-gtm-module";

const BannerGame = (props) => {
 
  let city = "";
  switch (props.step) {
    case 1:
      city = "NewYork";
      break;
    case 2:
      city = "London";
      break;
    case 3:
      city = "Amsterdam";
      break;
    case 4:
      city = "Croatia";
      break;
    default:
      city = "YourScore";
      break;
  }

  return (
    <div className="ac-game-navigator">
      
      <div className="ac-container">
        
        <span className="ac-col ac-col-3 ac_banner_timer">{props.time}</span>
        {/* <p className="ac-col ac-col-2"> */}
        {/* <span className="ac-city-name ac-city-name-1">	{city}</span>
					<span className="ac-city-name ac-city-name-2">	{city}</span> */}
        {/* </p> */}
        <div className="ac-col ac-col-1">
          <span>
            <span className={props.step === 1 ? "ac-active" : null}>
              <span>יציע <bdi>1</bdi></span>
            </span>
            
          </span>
          <span>
            <span className={props.step === 2 ? "ac-active" : null}>
            <span>יציע <bdi>2</bdi></span>
            </span>
          </span>
          <span>
            <span className={props.step === 3 ? "ac-active" : null}>
               <span>יציע <bdi>3</bdi></span>
            </span>
          </span>
          <span>
            <span className={props.step === 4 || props.step === 5 ? "ac-active" : null}>
               <span>יציע <bdi>4</bdi></span>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};
export default BannerGame;
