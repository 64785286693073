import React, { useState } from "react";
import { Link } from "react-router-dom";
import pdf from "../assets/takanon.pdf";
import logo from "../assets/img/findoreo@2x.png";
import Img from "../assets/img/img.png";
import "../assets/css/TakanonPage.css";
import GamePage from "./GamePage.jsx";
import TagManager from "react-gtm-module";

export const GoalsPage = (props) => {
  if (props.isStart) {
    return (
      <GamePage
        VR360={props.VR360}
        steps={props.steps}
        setIsNotYalla={props.setIsNotYalla}
        isNotYalla={props.isNotYalla}
        timer={props.timer}
        handleStart={props.handleStart}
        handlePause={props.handlePause}
        handleResume={props.handleResume}
        handleReset={props.handleReset}
        isDev={props.isDev}
      />
    );
  }
  return (
    <div className="ac-takanon-page">
      <div className="ac-overlay"></div>
      <div className="ac-container">
        {/* <div className="bread">
          <Link
            to="/"
            onClick={() => {
              window.location.replace("https://football-milkaoreo.activated.digital/");
              TagManager.dataLayer({ dataLayer: { event: "homepage" } });
            }}
          >
            בית{" "}
          </Link>{" "}
          {" > "}
          <Link
            to="/SharePage"
            onClick={() => {
              TagManager.dataLayer({ dataLayer: { event: "true_friend" } });
            }}
          >
            חבר אמיתי משתף
          </Link>
        </div> */}
      

    
        <a href={pdf}  onClick={() => {
            // window.location.replace("https://football-milkaoreo.activated.digital/");
            TagManager.dataLayer({ dataLayer: { event: "Takanon" } });
          }}
          target="_blank"
          className="ac-takaon-link"
        >
          להורדת התקנון
        </a>
       
      </div>
    </div>
  );
};

export default GoalsPage;
