import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import FormFinal from "../Components/FormFinal.jsx";
import logo from "../assets/img/findoreo@2x.png";
import image6546 from "../assets/img/football/6546.webp";

import "../assets/css/FormPage.css";

const FormPage = (props) => {
  // const location = useLocation();
  
  // useEffect(() => {
  //   console.log('FormPage mounted'); // Debug log
  //   console.log('Location:', location); // Debug log
  // }, [location]);
  return (
    <div className="ac-form-page">
      <div className="ac-overlay"></div>
      {/* <div className="ac-img-wrapper">
        <img src={logo} alt="Logo" className="ac-img-responive" />
      </div> */}
      <img src={image6546} alt="" className="ac-img-responive ac-image6546" />
      
      
        

        
        {/* {location?.time} */}
         {/* <FormFinal
            time={location?.time}
            utmVal={location?.utm_val}
            utmSource={location?.utmSource}
            utmMedium={location?.utmMedium}
            isDev={location?.isDev}
            rot_step1={location?.rot_step1}
            rot_step2={location?.rot_step2}
            rot_step3={location?.rot_step3}
            rot_step4={location?.rot_step4}
            serverStart={location?.serverStart}
            activatedStart={location?.activatedStart}
            serverEndClock={location?.serverEndClock}
         />  */}
          <FormFinal 
           isStart={props.isStart}
           setIsStart={props.setIsStart}
           isRealMobileDevice={props.isRealMobileDevice}
           utmMedium={props.utmMedium}
           utmSource={props.utmSource}
           utmTerm={props.utmTerm}
           utmCampaign={props.utmCampaign}
          />
      
     
    </div>
  );
};

export default FormPage;
