import React, { useState } from "react";
import UploadForm from "../Components/UploadForm";
import logo from "../assets/img/football/logo.webp";
import image6546 from "../assets/img/football/6546.webp";
import "../assets/css/UploadForm.css";

export const GamePage = (props) => {


  return (
    <div className="ac-upload-form">
        <img src={image6546} alt="" className="ac-img-responive ac-image6546" />
        <div className="ac-overlay"></div>
        <div className="ac-img-wrapper">
            <img src={logo} alt="הרכב מנצח" className="ac-img-responive ac-logo" />
        </div>
        <UploadForm/>
    </div>
  );
};

export default GamePage;
