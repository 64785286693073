import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/football/logo.webp";
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from "react-accessible-accordion";
// import "react-accessible-accordion/dist/fancy-example.css";
import "../assets/css/FaqPage.css";
import GamePage from "./GamePage.jsx";
import TagManager from "react-gtm-module";

export const FaqPage = (props) => {
  return (
    <div className="ac-faq-page"
    style={{
      backgroundImage: `url(${
        process.env.PUBLIC_URL + "/img/Mask-Group-6.webp"
      })`,
      backgroundPosition: "center",
      backgroundRepeat: "noRepeat",
      backgroundSize: "100%",
    }}>
      <div className="ac-overlay"></div>
      <div className="ac-container">
      <div className="ac-img-wrapper">
          <img src={logo} alt="Logo" className="ac-img-responive ac-logo" />
        </div>
        <div className="ac-close-wrapper">
          <Link to="/" onClick={() => {
              // window.location.replace("https://football-milkaoreo.activated.digital/");
              TagManager.dataLayer({ dataLayer: { event: "lets_start" } });
            }} className="ac-close">
            <div className="ac-inner-btn">
              <span className="ac-icon-svg">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={19.243}
                  height={19.243}
                  
                >
                  <g
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="square"
                    strokeWidth={3}
                    data-name="Group 53"
                  >
                    <path d="m17.121 2.121-15 15" data-name="Line 3" />
                    <path d="m17.121 17.121-15-15" data-name="Line 4" />
                  </g>
                </svg>
              </span>
            </div>
          </Link>
        </div>
        <span className="ac-faq-title">שאלות ותשובות</span>
        {/* <span className="ac-q">
            מה התאריכים של הפעילות?
        </span>
        <span className="ac-a">
            בין התאריכים: 19.06.2024 בשעה 10:00 עד 01.07.2024 בשעה 23:59 .
        </span> */}
        <span className="ac-q">מה התאריכים של הפעילות?</span>
        <span className="ac-a">בין התאריכים: 19.06.2024 בשעה 19:00 עד 01.07.2024<br/>
             בשעה 23:59.
        </span>
        <span className="ac-q">מאיזה גיל אפשר להשתתף במשחק?</span>
        <span className="ac-a">
            מגיל 18 . בכפוף לאישור אפוטרופוס ניתן להשתתף<br/>
            מגיל 16.
        </span>
        <span className="ac-q">
          איך מוסיפים חשבונית?
        </span>
        <span className="ac-a">
            אחרי שמסיימים לשחק ומזינים פרטים, מופיעות<br/>
            הנחיות להעלאת החשבונית. 

        </span>
        <span className="ac-q">מה אני עושה אם אין לי חשבונית כרגע?</span>
        <span className="ac-a">
          נוכל לשלוח לך <span className="ac-sms-word-reg">SMS</span> עם קישור מיוחד, שמאפשר<br/>
          להתחבר אליו מתי שרוצים ולהוסיף באמצעותו<br/>
          חשבונית עד גמר הפעילות
        </span>
        {/* <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="ac-question">
                  {" "}
                  מה התאריכים של הפעילות ביקום הזה?
                </span>
                <span className="ac-arrow">
                  <span></span>
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>בין התאריכים: 10.11.22 בשעה 10:00 עד 24.11.22 בשעה 23:59.</p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="ac-question">
                  {" "}
                  מאיזה גיל אפשר להשתתף במשחק?
                </span>
                <span className="ac-arrow">
                  <span></span>
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>מגיל 18. בכפוף לאישור אפוטרופוס ניתן להשתתף מגיל 16.</p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="ac-question"> איך מוסיפים חשבונית?</span>
                <span className="ac-arrow">
                  <span></span>
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                אחרי שמסיימים לשחק ומזינים פרטים, מופיעות הנחיות להעלאת
                החשבונית.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="ac-question">
                  {" "}
                  מה אני עושה אם אין לי חשבונית כרגע?
                </span>
                <span className="ac-arrow">
                  <span></span>
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                נוכל לשלוח לך SMS עם קישור מיוחד, שמאפשר להתחבר אליו מתי שרוצים
                ולהוסיף באמצעותו חשבונית עד גמר הפעילות.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="ac-question">מה הפרסים?</span>
                <span className="ac-arrow">
                  <span></span>
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                מיני קופר לזוכה במקום הראשון (מקסימום התרגשות!) בנוסף, בכל יום
                נחלק 5 כרטיסים זוגיים לקולנוע ל- 5 המקומות הראשונים באותו היום.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="ac-question"> אפשר לזכות בכמה פרסים?</span>
                <span className="ac-arrow">
                  <span></span>
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                אפשר לזכות בפרס היומי פעם אחת ואולי גם לזכות במיני קופר, במידה
                והתוצאה שלך תהיה הטובה ביותר בכל תקופת הפעילות. בהצלחה!
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="ac-question">
                  כמה פעמים אפשר לשחק באוראו במולטיוורס?
                </span>
                <span className="ac-arrow">
                  <span></span>
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                אפשר לשחק ללא הגבלה. כדי לזכות יש לצרף חשבונית בשווי 25 ₪ על
                מגוון עוגיות אוראו, עבור כל טופס השתתפות.{" "}
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="ac-question">איך אפשר ליצור איתכם קשר?</span>
                <span className="ac-arrow">
                  <span></span>
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                אנו מזמינים אותך לשלוח לנו הודעה פרטית בעמוד{" "}
                <a
                  href="https://www.instagram.com/oreo_il/"
                  target="_blank"
                  className="ac-insta"
                  rel="noopener"
                >
                  האינסטגרם של אוראו {`>>`}
                </a>
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion> */}
        {/* <Link
          to="/"
          onClick={() => {
            // window.location.replace("https://football-milkaoreo.activated.digital/");

            TagManager.dataLayer({ dataLayer: { event: "lets_start" } });
          }}
          className="ac-btn ac-start-game-btn"
        >
          <div className="ac-inner-btn">
            <span>למשחק</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.167"
              height="21.168"
              viewBox="0 0 21.167 21.168"
            >
              <g data-name="Group 32" transform="translate(0 10.584)">
                <path
                  fill="#0057c8"
                  d="M0 0l21.167 10.584v-21.168z"
                  data-name="Path 17"
                ></path>
              </g>
            </svg>
          </div>
        </Link> */}
      </div>
    </div>
  );
};

export default FaqPage;
