import React, { useState,useEffect } from 'react';
import TagManager from "react-gtm-module";
import { FacebookShareButton, WhatsappShareButton } from "react-share";
import facebookIcon from "../assets/img/football/facebook.svg";
import WhatsappIcon from "../assets/img/football/whatssapp.svg";
import logo from "../assets/img/football/logo.webp";
import CryptoJS from "crypto-js";

// import textbg from "../assets/img/football/1.webp";
import axios from 'axios';

const FormFinal = (props) => {
 
  const [text_4, setText_4] = useState('');
  const [email_1, setEmail_1] = useState('');
  const [text_1, setText_1] = useState('');
  const [image, setImage] = useState(null);
  const [hidden_11, setHidden_11] = useState(null);
  const [hidden_1, setHidden_1] = useState(null); // אישור תקנון
  const [hidden_2, setHidden_2] = useState(false); // אישור צירוף חשבונית
  const [hidden_3, setHidden_3] = useState(null); // utm_medium
  const [hidden_4, setHidden_4] = useState(null); // utm_source
  const [hidden_5, setHidden_5] = useState(null); //utm_term
  const [hidden_6, setHidden_6] = useState(null); // utm_camp
  const [hidden_10, setHidden_10] = useState(null);
  const [hidden_12, setHidden_12] = useState(null); // is_dev
  const [message, setMessage] = useState('');
  const [leadSent, setLeadSent] = useState(false);
  const [isHaveBill, setIsHaveBill] = useState(false);
  const [isGotSms, setIsGotSms] = useState(false);
  const [isDidntGetSms, setIsDidntGetSms] = useState(false);
  const [finalTime, setFinalTime] = useState('');
  const secretKey = process.env.REACT_APP_SECRET_KEY;

  
  const decryptData = (ciphertext, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  const loadFromLocalStorage = (key, secretKey) => {
    const encryptedData = localStorage.getItem(key);
    if (encryptedData) {
      return decryptData(encryptedData, secretKey);
    }
    return null;
  };

  const decryptedUserData = loadFromLocalStorage("user", secretKey);


  useEffect(() => {

    
    setHidden_10(decryptedUserData);
    
    setHidden_3(props.utmMedium);
    setHidden_4(props.utmSource);
    setHidden_5(props.utmTerm);
    setHidden_6(props.utmCampaign)
    // alert(hidden_3);
  }, [decryptedUserData]);

  
 
  
  useState(() => {
      const header = document.getElementsByTagName('header')[0];
      header.style.display = 'none';

      if(props.isRealMobileDevice === true){
        setHidden_12('כן');
      } else{
        setHidden_12('לא');
      }
    
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
  
  };

  useEffect(() => {
    if (image) {
    
      setHidden_11(`https://milkaoreo.activated.digital/invoices_from_app/user_images/${image.name}`);
    }
  }, [image]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (hidden_2 && hidden_1) {

      // Save invoice in server in "invoices_from_app/user_images" folder
      const formDataImg = new FormData();
      formDataImg.append('file', image);

      try {
        const response = await axios.post('https://milkaoreo.activated.digital/invoices_from_app/', formDataImg, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
       
  
        if (response.data.status === 'ok') {
          setMessage(`File uploaded successfully: ${response.data.filename}`);
        } else {
          setMessage('File upload failed');
        }
      } catch (error) {
        setMessage('An error occurred while uploading the file');
      }

   

      try {
        let res = await fetch(
          "https://crud.activated.digital/app/app/f?id=RuBNfQ",
          {
            method: "POST",
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({ text_4, email_1, text_1, hidden_11, hidden_1, hidden_2, hidden_3, hidden_4, hidden_5, hidden_6, hidden_10, hidden_12 }),
          }
        );
        let resJson = await res.json();
        if (res.status === 200) {
          setLeadSent(true);
          TagManager.dataLayer({
            dataLayer: { event: "send_reciept_finish" },
          });
          setText_4("");
          setEmail_1("");
          setText_1("");
          setMessage("User created successfully");
          // setAddImgPopup(true); // Uncomment if you have a popup handling
        } else {
          setMessage("Some error occurred");
        }
      } catch (err) {
        // console.log(err);
      }
    } else {
      alert("אנה סמן שני צ׳קבוקסים");
    }
  };

  const handleSubmitSMS = async (e) => {
   
    e.preventDefault();
    const dataSend = {
      phone: text_1,
    };


    try {
      let res = await fetch("https://football-milkaoreo.activated.digital/sendSMS2.php", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(dataSend),
      });
      
      setLeadSent(false);
      if(!isGotSms){
        setIsGotSms(true);
      } else{
        setIsDidntGetSms(true);
      }
        
      
      let resJson = await res.json();
      if (res.status === 200) {
      
        setIsGotSms(true);
      } else {
     
        setIsDidntGetSms(true);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  

  return (
   
    <>
    {isHaveBill ? (
      isDidntGetSms ? (
        <div className="ac-sms-wrapper ac-didntget-sms">
          <div className="ac-img-wrapper">
                <img src={logo} alt="הרכב מנצח" className="ac-img-responive ac-logo" />
            </div>
            <div id="ac-recipe-game">
              {/* <img src={textbg} alt="" className="ac-img-responive ac-text-bg" /> */}
              <div className="ac-inner">
                <div className="ac-close-wrapper">
                  <button onClick={() => setIsHaveBill(false)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18.059"
                      height="18.059"
                      viewBox="0 0 18.059 18.059"
                    >
                      <g data-name="Group 475" transform="translate(-61.246 -225.649)">
                        <g data-name="Group 395" transform="translate(64.645 226.231)">
                          <path
                            fill="#fff"
                            d="M0 0a1.991 1.991 0 00-2.815 0 1.989 1.989 0 000 2.815l14.077 14.078a1.991 1.991 0 102.815-2.816z"
                            data-name="Path 219"
                          ></path>
                        </g>
                        <g data-name="Group 396" transform="translate(61.829 240.309)">
                          <path
                            fill="#fff"
                            d="M0 0a1.991 1.991 0 002.815 2.815l14.078-14.077a1.991 1.991 0 000-2.815 1.991 1.991 0 00-2.816 0z"
                            data-name="Path 220"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>
              
                <span className="ac-text ac-text-sms" style={{ direction: "rtl" }}>
                    אוי, נראה שמשהו השתבש.<br/> שננסה שוב?
                </span>           
                <form onSubmit={handleSubmitSMS}>
                  <button
                    type="submit"
                    onClick={() => {
                      TagManager.dataLayer({ dataLayer: { event: "sms_reciept" } });
                    }}
                    className="ac-btn ac-send-sms-btn"
                  >
                    <div className="ac-inner-btn">
                      <span>
                      אני רוצה לנסות שוב
                      </span>
                      <span className="ac-arrows">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.75"
                          height="18.195"
                          viewBox="0 0 15.75 18.195"
                        >
                          <g id="Group_11" data-name="Group 11" transform="translate(-136 -608.792)">
                            <g id="ctrl-left" transform="translate(134.504 608.395)">
                              <g id="Group_10" data-name="Group 10" transform="translate(8.496 0.397)">
                                <path
                                  id="Path_7"
                                  data-name="Path 7"
                                  d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z"
                                  transform="translate(-8.496 -0.397)"
                                  fill="#fff"
                                />
                              </g>
                            </g>
                            <g id="ctrl-left-2" data-name="ctrl-left" transform="translate(127.504 608.395)">
                              <g id="Group_10-2" data-name="Group 10" transform="translate(8.496 0.397)">
                                <path
                                  id="Path_7-2"
                                  data-name="Path 7"
                                  d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z"
                                  transform="translate(-8.496 -0.397)"
                                  fill="#fff"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                    </div>
                  </button>
                </form>
              </div>
            </div>
        </div>
      ) : (
        isGotSms? (
          <div className='ac-sms-wrapper ac-got-sms'>
            <div className="ac-img-wrapper">
                <img src={logo} alt="הרכב מנצח" className="ac-img-responive ac-logo" />
            </div>
            <div id="ac-recipe-game">
              {/* <img src={textbg} alt="" className="ac-img-responive ac-text-bg" /> */}
              <div className="ac-inner">
                <div className="ac-close-wrapper">
                  <button onClick={() => setIsHaveBill(false)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18.059"
                      height="18.059"
                      viewBox="0 0 18.059 18.059"
                    >
                      <g data-name="Group 475" transform="translate(-61.246 -225.649)">
                        <g data-name="Group 395" transform="translate(64.645 226.231)">
                          <path
                            fill="#fff"
                            d="M0 0a1.991 1.991 0 00-2.815 0 1.989 1.989 0 000 2.815l14.077 14.078a1.991 1.991 0 102.815-2.816z"
                            data-name="Path 219"
                          ></path>
                        </g>
                        <g data-name="Group 396" transform="translate(61.829 240.309)">
                          <path
                            fill="#fff"
                            d="M0 0a1.991 1.991 0 002.815 2.815l14.078-14.077a1.991 1.991 0 000-2.815 1.991 1.991 0 00-2.816 0z"
                            data-name="Path 220"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>
              
                <span className="ac-text ac-text-sms" style={{ direction: "rtl" }}>
                נו, קיבלת? שלחנו לך <span className="ac-sms-word-reg">SMS</span><br/> עכשיו תורך לקנות ממגוון מוצרי מילקה ו/<br/>או אוראו בסך 30 ₪<br/> ולהעלות חשבונית<br/><br/> בהצלחה!
                </span>           
                <form onSubmit={handleSubmitSMS}>
                  <button
                    type="submit"
                    onClick={() => {
                      TagManager.dataLayer({ dataLayer: { event: "sms_reciept" } });
                    }}
                    className="ac-btn ac-send-sms-btn"
                  >
                    <div className="ac-inner-btn">
                      <span>
                      לא קיבלתי, שלחו שוב
                      </span>
                      <span className="ac-arrows">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.75"
                          height="18.195"
                          viewBox="0 0 15.75 18.195"
                        >
                          <g id="Group_11" data-name="Group 11" transform="translate(-136 -608.792)">
                            <g id="ctrl-left" transform="translate(134.504 608.395)">
                              <g id="Group_10" data-name="Group 10" transform="translate(8.496 0.397)">
                                <path
                                  id="Path_7"
                                  data-name="Path 7"
                                  d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z"
                                  transform="translate(-8.496 -0.397)"
                                  fill="#fff"
                                />
                              </g>
                            </g>
                            <g id="ctrl-left-2" data-name="ctrl-left" transform="translate(127.504 608.395)">
                              <g id="Group_10-2" data-name="Group 10" transform="translate(8.496 0.397)">
                                <path
                                  id="Path_7-2"
                                  data-name="Path 7"
                                  d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z"
                                  transform="translate(-8.496 -0.397)"
                                  fill="#fff"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                    </div>
                  </button>
                </form>
              </div>
            </div>
          </div>
        ) : (
        <div className='ac-sms-wrapper'>
          <div className="ac-img-wrapper">
              <img src={logo} alt="הרכב מנצח" className="ac-img-responive ac-logo" />
          </div>
          <div id="ac-recipe-game"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/img/1.webp"
            })`,
            backgroundPosition: "center",
            backgroundRepeat: "noRepeat",
            backgroundSize: "100%",
          }}>
            {/* <img src={textbg} alt="" className="ac-img-responive ac-text-bg" /> */}
            <div className="ac-inner">
              <div className="ac-close-wrapper">
                <button onClick={() => setIsHaveBill(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.059"
                    height="18.059"
                    viewBox="0 0 18.059 18.059"
                  >
                    <g data-name="Group 475" transform="translate(-61.246 -225.649)">
                      <g data-name="Group 395" transform="translate(64.645 226.231)">
                        <path
                          fill="#fff"
                          d="M0 0a1.991 1.991 0 00-2.815 0 1.989 1.989 0 000 2.815l14.077 14.078a1.991 1.991 0 102.815-2.816z"
                          data-name="Path 219"
                        ></path>
                      </g>
                      <g data-name="Group 396" transform="translate(61.829 240.309)">
                        <path
                          fill="#fff"
                          d="M0 0a1.991 1.991 0 002.815 2.815l14.078-14.077a1.991 1.991 0 000-2.815 1.991 1.991 0 00-2.816 0z"
                          data-name="Path 220"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </button>
              </div>
              <span className="ac-title">
              חסרה לך חשבונית?
              </span>
              <br />
              <span className="ac-text ac-text-sms" style={{ direction: "rtl" }}>
              על מנת לזכות,<br/> עליך להעלות חשבונית על קניית<br/> מוצרי מילקה ו/או אוראו בסך 30 ₪<br/> עד גמר הפעילות בתאריך 01.07.2024<br/><br/> איך?<br/> קליק על הכפתור מטה ואנחנו נשלח<br/> לך <span className="ac-sms-word-reg">SMS</span> עם לינק            </span>
            
              <form onSubmit={handleSubmitSMS}>
                <button
                  type="submit"
                  onClick={() => {
                    TagManager.dataLayer({ dataLayer: { event: "send_sms" } });
                  }}
                  className="ac-btn ac-send-sms-btn"
                >
                  <div className="ac-inner-btn">
                    <span>
                      שלחו לי <span className="ac-sms-word-reg">SMS</span>
                    </span>
                    <span className="ac-arrows">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.75"
                        height="18.195"
                        viewBox="0 0 15.75 18.195"
                      >
                        <g id="Group_11" data-name="Group 11" transform="translate(-136 -608.792)">
                          <g id="ctrl-left" transform="translate(134.504 608.395)">
                            <g id="Group_10" data-name="Group 10" transform="translate(8.496 0.397)">
                              <path
                                id="Path_7"
                                data-name="Path 7"
                                d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z"
                                transform="translate(-8.496 -0.397)"
                                fill="#fff"
                              />
                            </g>
                          </g>
                          <g id="ctrl-left-2" data-name="ctrl-left" transform="translate(127.504 608.395)">
                            <g id="Group_10-2" data-name="Group 10" transform="translate(8.496 0.397)">
                              <path
                                id="Path_7-2"
                                data-name="Path 7"
                                d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z"
                                transform="translate(-8.496 -0.397)"
                                fill="#fff"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </span>
                  </div>
                </button>
              </form>
            </div>
          </div>
        </div>
        )
      )
    ) : leadSent ? (
      <div className="ac_help_center">
        <div className="ac-thanks">
          <div className="ac-text-box">
            <span className="ac-text ac-text-r ac-text-r-1">
              תודה על השתתפותך בפעילות
              <br />
              ׳מילקה אוראו ההרכב המנצח׳ <br />
              פרטיך נשמרו בהצלחה. אנו ניצור איתך <br />
              קשר במקרה של זכייה. <br />
              <br />
              אגב, ניתן לשחק שוב ושוב על מנת להגדיל <br />
              את סיכוייך לזכות. בהצלחה!
            </span>
            <div
              onClick={() => {
                const currentUrl = window.location.origin;
                window.location.assign(currentUrl);
              }}
              className="ac-btn ac-start-game-btn"
              role="button"
            >
              <div className="ac-inner-btn">
                <span>להתחלת המשחק</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.75"
                  height="18.195"
                  viewBox="0 0 15.75 18.195"
                >
                  <g id="Group_11" data-name="Group 11" transform="translate(-136 -608.792)">
                    <g id="ctrl-left" transform="translate(134.504 608.395)">
                      <g id="Group_10" data-name="Group 10" transform="translate(8.496 0.397)">
                        <path
                          id="Path_7"
                          data-name="Path 7"
                          d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z"
                          transform="translate(-8.496 -0.397)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                    <g id="ctrl-left-2" data-name="ctrl-left" transform="translate(127.504 608.395)">
                      <g id="Group_10-2" data-name="Group 10" transform="translate(8.496 0.397)">
                        <path
                          id="Path_7-2"
                          data-name="Path 7"
                          d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z"
                          transform="translate(-8.496 -0.397)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <span className="ac-title-share">שתף את החברים</span>
            <div className="ac-wrapper-btns">
              <WhatsappShareButton
                title={"שחקתי באוראו"}
                url={`אני כבר השתתפתי בפעילות מילקה אוראו ההרכב המנצח ואולי אזכה בחבילה חלומית לאירוע מוזיקה או ספורט בחו''ל בשווי 20,000 ₪. ומה איתך?  https://football-milkaoreo.activated.digital/`}                onClick={() => {
                  TagManager.dataLayer({ dataLayer: { event: "share_whatsapp" } });
                }}
              >
                <img src={WhatsappIcon} alt="whatsapp" className="ac-img-responive" />
              </WhatsappShareButton>
              <FacebookShareButton
                url={"https://football-milkaoreo.activated.digital/"}
                quote={"שחקתי באוראו"}
                onClick={() => {
                  TagManager.dataLayer({ dataLayer: { event: "share_facebook" } });
                }}
              >
                <img src={facebookIcon} alt="facebook" className="ac-img-responive" />
              </FacebookShareButton>
            </div>
          </div>
        </div>
      </div>
      
    ) : (
      <div className="ac_help_center">
        <form onSubmit={handleSubmit}>
        <div className="ac-text-box">
          <span className="ac-text ac-text-r ac-text-r-1">
            כדי שנוכל ליצור קשר במקרה של זכייה, <br />
            נצטרך מעט פרטים וצילום חשבונית:
          </span>
        </div>
        <div className="ac-input">
          <label className="ac-hide">שם:</label>
          <input
            type="text"
            value={text_4}
            placeholder="שם"
            onChange={(e) => setText_4(e.target.value)}
            required
          />
        </div>
        <div className="ac-input">
          <label className="ac-hide">דוא"ל:</label>
          <input
            type="email"
            value={email_1}
            placeholder='דוא"ל'
            onChange={(e) => setEmail_1(e.target.value)}
            required
          />
        </div>
        <div className="ac-input">
          <label className="ac-hide">נייד:</label>
          <input
            type="text"
            value={text_1}
            placeholder="נייד"
            onChange={(e) => setText_1(e.target.value)}
            required
          />
        </div>
        <div className="ac-input">
          <input
            id="upload-button"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
          <label htmlFor="upload-button">
            <span className="ac-icon-upload-image">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={26.55}
                height={24.195}
              >
                <g
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="square"
                  strokeWidth={3}
                  data-name="Group 32"
                  transform="translate(1.5 1.5)"
                >
                  <circle
                    cx={4.317}
                    cy={4.317}
                    r={4.317}
                    data-name="Ellipse 3"
                    transform="translate(7.457 7.85)"
                  />
                  <path
                    d="M21.195 21.195H2.355A2.355 2.355 0 0 1 0 18.84V6.28a2.355 2.355 0 0 1 2.355-2.355h3.532L8.242 0h7.065l2.355 3.925h3.532A2.355 2.355 0 0 1 23.55 6.28v12.56a2.355 2.355 0 0 1-2.355 2.355Z"
                    data-name="Path 18"
                  />
                </g>
              </svg>
            </span>{" "}
            צילום חשבונית
          </label>
        </div>
        <button
          type="button"
          className="ac-no-image"
          onClick={() => {
            setIsHaveBill(true);
            setLeadSent(false);
            TagManager.dataLayer({
              dataLayer: { event: "no_reciept" },
            });
          }}
        >
          ואם אין לי חשבונית כרגע?
        </button>
        <button type="submit" className="send">
          <span>שליחה</span>
          <span className="ac-arrows">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.75"
              height="18.195"
              viewBox="0 0 15.75 18.195"
            >
              <g id="Group_11" data-name="Group 11" transform="translate(-136 -608.792)">
                <g id="ctrl-left" transform="translate(134.504 608.395)">
                  <g id="Group_10" data-name="Group 10" transform="translate(8.496 0.397)">
                    <path
                      id="Path_7"
                      data-name="Path 7"
                      d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z"
                      transform="translate(-8.496 -0.397)"
                      fill="#fff"
                    />
                  </g>
                </g>
                <g id="ctrl-left-2" data-name="ctrl-left" transform="translate(127.504 608.395)">
                  <g id="Group_10-2" data-name="Group 10" transform="translate(8.496 0.397)">
                    <path
                      id="Path_7-2"
                      data-name="Path 7"
                      d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z"
                      transform="translate(-8.496 -0.397)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </span>
        </button>
        <div className="ac-checkbox">
          <label>
            <input
              type="checkbox"
              checked={hidden_2}
              onChange={(e) => setHidden_2(e.target.value)}
            />
            <span>
               ידוע לי שכדי לזכות, צריך לצלם חשבונית על מוצרים בסך 30 שח ממגוון שוקולד או עוגיות מילקה ו/או עוגיות אוראו.
            </span>
          </label>
        </div>
        <div className="ac-checkbox">
          <label>
            <input
              type="checkbox"
              checked={hidden_1}
              onChange={(e) => setHidden_1(e.target.value)}
            />
            <span>קראתי ואני מסכים לתקנון</span>
          </label>
        </div>
        {message && <p>{message}</p>}
      </form>
      </div>
      
    )}
  </>
  
    
  );
};

export default FormFinal;
