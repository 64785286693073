import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import FoundOreo from "./Popup/FoundOreo";
import { NotFoundData } from "./Popup/NotFoundData";
import NotFoundOreo from "./Popup/NotFoundOreo";
import { ServerTime } from "./Utility";
import ProgressBar from "./ProgressBar";  // Ensure this import path is correct
import $ from 'jquery';
import CryptoJS from "crypto-js";
import TagManager from "react-gtm-module";


const VRScene = (props) => {
  const hasRun = useRef(false);
  const [serverStop, setServerStop] = useState();
  const [response, setResponse] = useState();
  const [serverStart, setServerStart] = useState();
  const location = useLocation();
  const [utmSource] = useState(new URLSearchParams(location?.search).get("utm_source"));
  const [utmMedium] = useState(new URLSearchParams(location?.search).get("utm_medium"));
  const [utm_val] = useState(location?.search);
  const [requiredCoordinates, setRequiredCoordinates] = useState(2);
  const [progress, setProgress] = useState(0);
  const [foundCoordinates, setFoundCoordinates] = useState([]);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [thatSecretKey, setThatSecretKey] = useState(0);
  const timerRef = useRef(null);

  const stepRequirements = {
    0: 2,
    1: 2,
    2: 3,
    3: 3,
    4: 3
  };
  const secretKey = process.env.REACT_APP_SECRET_KEY;

  const encryptData = (data, secretKey) => {
    if (!secretKey) {
      throw new Error("Secret key is not defined");
    }
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  };

  const saveToLocalStorage = (key, data, secretKey) => {
    const encryptedData = encryptData(data, secretKey);
    localStorage.setItem(key, encryptedData);
  };

  const isFound = (position, id) => {
    console.log('isFound:', { position, id });

    if (hasRun.current) {
      return;
    }
    hasRun.current = true;

    const storeKey = `game_status_${props.statusGame}`;
    let storedData = JSON.parse(localStorage.getItem(storeKey)) || [];

    // Check if position already exists in local storage
    const exists = storedData.some((item) => item.position === position);

    if (exists) {
      hasRun.current = false;
      return;
    }

    // Add the new position to local storage
    storedData.push({ position });
    localStorage.setItem(storeKey, JSON.stringify(storedData));

    setFoundCoordinates((prev) => {
      const newFoundCoordinates = [...prev, { position }];
      const progress = (newFoundCoordinates.length / requiredCoordinates) * 100;
      setProgress(progress);

      if (newFoundCoordinates.length === requiredCoordinates) {
        props.setIsNotFound(false);
        if (props.statusGame === Object.keys(stepRequirements).length - 1) {
          clearInterval(timerRef.current);
          const formattedTime = $('#ac-game-timer span').text();
          saveToLocalStorage("user", formattedTime, secretKey);
          TagManager.dataLayer({ dataLayer: { event: "finished_game" } });

          if (requiredCoordinates === 3) {
            let el = document.getElementById("passtothanks");
            if (el) {
              el.click();
            }
          }
        }
      }

      return newFoundCoordinates;
    });

    $(`#${id}`).attr('material', 'opacity: 1; transparent: true;');
   

    setTimeout(() => {
      hasRun.current = false;
    }, 2000);
  };

  const IsFoundNot = () => {
    props.setNotOreo(false);
  };

  useEffect(() => {
    // Clear local storage on page load
    localStorage.clear();

    // Start the timer
    timerRef.current = setInterval(() => {
      setElapsedTime((prev) => prev + 1);
    }, 1000);

    return () => {
      // Clear the timer on unmount
      clearInterval(timerRef.current);
    };
    setThatSecretKey(secretKey);
  }, [secretKey]);

  useEffect(() => {
    props.setYalah(false);

    const getServerTime = ServerTime;
    setServerStart(getServerTime);

    if (!props.Yalah) {
      props.run();
    }

    return () => {
      // props.reset(); // Uncomment if you need to reset on unmount
    };
  }, [props.Yalah]);

  useEffect(() => {
    const reqCoordinates = stepRequirements[props.statusGame] || 3;
    setRequiredCoordinates(reqCoordinates);
    setFoundCoordinates([]); // Reset foundCoordinates when statusGame changes
    setProgress(0); // Reset progress when statusGame changes

    // Reset the materials of all a-image elements
    $('a-image').attr('material', 'opacity: 0; transparent: true');
    // $('a-image').attr('material', 'opacity: 1; transparent: true');
    if (
      Array.isArray(props.steps) &&
      typeof props.steps[props.statusGame - 1] === "function"
    ) {
      setResponse(
        props.steps[props.statusGame - 1]((position, id) => {
          isFound(position, id);
        }, IsFoundNot, reqCoordinates)
      );
    } else {
      setResponse("error");
    }
  }, [props.statusGame, requiredCoordinates]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  return (
    <div>
      <div id="MAINWRAPPER">
        <div id="WRAP">
          <ProgressBar 
            progress={progress} 
            current={foundCoordinates.length} 
            total={requiredCoordinates} 
          />
          <div id="ac-game-timer">
            <span>{formatTime(elapsedTime)}</span>
          </div>
      
          <div className="frame">{response}</div>
        </div>
      </div>

      {!props.isNotFound ? (
        <FoundOreo
          step={props.statusGame}
          desactivate={props.setIsNotFound}
          replay={props.resume}
          change={props.changeStatus}
          time={props.time}
        />
      ) : null}
      {!props.notOreo ? (
        <NotFoundOreo
          desactivate={props.setNotOreo}
          replay={props.resume}
        />
      ) : null}

      <div style={{ display: "none" }}>
        <Link
          to={{
            pathname: "/GameOver",
            state: {
              time: props.timeer,
              utmMedium: utmMedium,
              utmSource: utmSource,
              utm_val: utm_val,
              isDev: props.isDev,
              serverStart: serverStart,
            },
          }}
          id="passtothanks"
        > </Link>
      </div>
    </div>
  );
};

export default VRScene;
